import { Paper } from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import { useAlert } from "react-alert";
import { baseUrl } from "../../../../services/config";
import { Spinner } from "react-bootstrap";
import ResultCard from "./ResultCard";
import ReactToPrint from "react-to-print";
import XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";
import ViewResult from "./ViewResult/ViewResult";
import ResulftCardForPrint from "./ResulftCardForPrint";
import { upperCase } from "lodash";
import ResultCardHifz from "./ResultCardHifz";
import ResulftCardHifzPrint from "./ResulftCardHifzPrint";
import { useSelector } from "react-redux";

const StudentWiseResult = () => {
  const alert = useAlert();

  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);

  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");

  const authtoken = localStorage.getItem("userToken");
  const [allsessions, setallsessions] = useState([]);
  const user = useSelector((state) => state.user.user);
  const [campuses, setallcampuses] = useState(user.campuses || []);
  const [selectedcampus, setselectedcampus] = useState("");
  const [currentsession, setcurrentsession] = useState("");
  const [selectedsection, setselectedsection] = useState("");
  const [allsubjects, setallsubjects] = useState([]);
  const [selectedsubject, setselectedsubject] = useState("");

  const [exampsTypeList, setexampsTypeList] = useState([]);
  const [selectedExamType, setselectedExamType] = useState("");
  const [printmodal, setprintmodal] = useState(false);
  const [allexamslist, setallexamslist] = useState([]);

  const [educationType, setEducationType] = useState(1);

  const [selectedclass, setselectedclass] = useState("");

  const [allclasses, setallclasses] = useState([]);
  const [allCampusSections, setallCampusSections] = useState([]);

  const [loading, setloading] = useState(false);

  const [campusDetail, setcampusDetail] = useState({});
  const [campusDetail2, setcampusDetail2] = useState({});

  const [studentFullResult, setstudentFullResult] = useState([]);
  const [viewModal, setviewModal] = useState(false);
  const [viewresult, setViewResult] = useState([]);
  const [includeAnnual, setIncludeAnnual] = useState(false);
  const [currentExam, setcurrentExam] = useState("");
  // const [result]

  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);
  const [weekList, setWeekList] = useState([]);

  const pageStyle = `@page{
        margin-top: 20px;
    }`;

  useEffect(() => {
    getSettingData();
    // getAllCampus();
    getAllSubjects();
    getAllExampTypes();
    getAllSessions();
    getWeekList();
    // getAllClasses()
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallsessions([...data.payload]);
        data.payload.forEach((session, index) => {
          if (session.active_academic_year == "1") {
            setcurrentsession(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getWeekList = () => {
    fetch(`${baseUrl}/weeks`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setWeekList([...data.payload]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllExamsList = (id) => {
    const data = {
      year_id: currentsession,
      campus_id: id,
    };

    fetch(`${baseUrl}/exam_for_session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setallexamslist([...data.payload]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllExampTypes = async () => {
    await fetch(`${baseUrl}/exam_type`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setexampsTypeList(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  const getAllSubjects = async () => {
    await fetch(`${baseUrl}/subject`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallsubjects(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  const onCampusChange = async (id) => {
    setselectedcampus(id);
    getAllExamsList(id);
  };

  const onChangeSession = (id) => {
    setcurrentsession(id);
  };

  const getAllClasses = () => {
    fetch(`${baseUrl}/studentclass`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallclasses([...data.payload]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onClassChange = (id) => {
    setselectedclass(id);

    fetch(`${baseUrl}/class_section/${selectedcampus}/${educationType}/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallCampusSections([...data.payload]);
      })
      .catch((err) => {
        console.log("section error", err);
      });
  };

  const getSectionsListOnTypeChange = (id) => {
    fetch(
      `${baseUrl}/class_section/${selectedcampus}/${id}${
        selectedclass ? "/" + selectedclass : ""
      }`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => {
        res.json().then((data) => {
          setallCampusSections(data.payload);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getStudentWiseResult = () => {
    if (selectedExamType === "" || selectedcampus === "") {
      return alert.error("Please select all fileds");
    }

    if (selectedsection === "" && selectedclass === "") {
      return alert.error("Please select class/section");
    }
    if (educationType !== "2" && selectedclass === "") {
      return alert.error("Please select class");
    }

    // if (selectedExamType == "" || selectedcampus == "" || selectedclass == "") {
    //     alert.error("Please select all fileds")
    // } else {
    setloading(true);
    let data = {
      year_id: currentsession,
      exam_id: selectedExamType,
      campus_id: selectedcampus,
      education_type: educationType,
      student_class_id: selectedclass,
      global_section_id: selectedsection,
      include_annual_term: includeAnnual ? 1 : 0,
    };
    // let data = {
    //     campus_id: "1",
    //     education_type: 1,
    //     exam_id: "81",
    //     global_section_id: "3",
    //     include_annual_term: 1,
    //     student_class_id: "12"
    // }
    fetch(`${baseUrl}/result-student-wise`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          setcampusDetail2(data.payload);
          if (data.payload.students_full_result.length > 0) {
            const arr = data.payload;
            arr.students_full_result.sort(
              (a, b) =>
                b.student_result_overall.gain_marks -
                a.student_result_overall.gain_marks
            );
            setViewResult(arr);
            const res = arr.students_full_result.sort(
              (a, b) => a.student.admission_id - b.student.admission_id
            );
            setcampusDetail(res);
            setloading(false);
          } else {
            setcampusDetail([]);
            setstudentFullResult([]);
            setloading(false);
          }
        } else if (!data.metadata.success) {
          Object.values(data.metadata.message).map((msg) => {
            alert.error(msg);
            setloading(false);
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });

    // }
  };

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  function getSheetData(data, header) {
    var fields = Object.keys(data[0]);
    var sheetData = data.map(function(row) {
      return fields.map(function(fieldName) {
        return row[fieldName] ? row[fieldName] : "";
      });
    });
    sheetData.unshift(header);
    return sheetData;
  }

  async function saveAsExcel() {
    var data = [
      {
        AdmNo: "BAL-79",
        name: "Ahmad",
        marks: [{ name: "ENG", marks: 100 }],
        subject: {
          id: 3,
          name: "ENGLISH",
          deleted_at: null,
          created_at: "2022-04-26T11:03:09.000000Z",
        },
      },
      {
        AdmNo: "BAL-80",
        name: "test",
        marks: [{ name: "ENG", marks: 100 }],
        subject: {
          id: 3,
          name: "ENGLISH",
          deleted_at: null,
          created_at: "2022-04-26T11:03:09.000000Z",
        },
      },
      {
        AdmNo: "BAL-81",
        name: "test2",
        marks: [{ name: "ENG", marks: 100 }],
        subject: {
          id: 3,
          name: "ENGLISH",
          deleted_at: null,
          created_at: "2022-04-26T11:03:09.000000Z",
        },
      },
    ];
    let header = ["AdmNo", "Name", "Result", "Total Marks"];

    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet1 = workbook.sheet(0);
      const sheetData = getSheetData(data, header);
      const totalColumns = sheetData[0].length;

      sheet1.cell("A1").value(sheetData);
      const range = sheet1.usedRange();
      const endColumn = String.fromCharCode(64 + totalColumns);
      sheet1.row(1).style("bold", true);
      sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
      range.style("border", true);
      return workbook.outputAsync().then((res) => {
        saveAs(res, "file.xlsx");
      });
    });
  }

  return (
    <Paper style={{ marginTop: "10px", marginBottom: "10px", padding: "10px" }}>
      <div className="row">
        <div className="col-md-3">
          <label>
            <b>Select Session</b>
          </label>
          <select
            onChange={(e) => onChangeSession(e.target.value)}
            class="form-select"
            style={{ height: 40 }}
            value={currentsession}
            aria-label="Default select example"
          >
            <option value="">Select Session</option>
            {allsessions.map((session) => (
              <option key={session.id} value={session.id}>
                {session.year}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-3">
          <label>
            <b>Select Campus</b>
          </label>
          <select
            style={{ height: 40 }}
            className="form-select"
            name="campus_id"
            value={selectedcampus}
            onChange={(e) => onCampusChange(e.target.value)}
            aria-label="Default select example"
          >
            <option value=""> Select Campus</option>
            {campuses.map((campus, index) => (
              <option key={index} value={campus.id}>
                {campus.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-3">
          <label>
            <b>Select Exam</b>
          </label>
          <select
            onChange={(e) => {
              setselectedExamType(e.target.value);
              let finded = allexamslist.find(
                (item) => item.id == e.target.value
              );
              setallclasses(finded.student_classes);
              setcurrentExam(finded?.exam_type?.name);
            }}
            class="form-select"
            style={{ height: 40 }}
            // value={currentsession}
            aria-label="Default select example"
          >
            <option value="">Select Exam</option>
            {allexamslist.map((exam) => (
              <option key={exam.id} value={exam.id}>
                {exam?.exam_name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-3">
          <label>
            <b>Select Class</b>
          </label>

          <select
            style={{ height: 40 }}
            className="form-select"
            name="class_id"
            // value={selectedclass}
            onChange={(e) => onClassChange(e.target.value)}
            aria-label="Default select example"
          >
            <option value=""> Select Class</option>
            {allclasses.map((campus, index) => (
              <option key={index} value={campus.id}>
                {campus.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-3">
          <label>
            <b>Select Education Type</b>
          </label>
          <select
            className="form-select"
            name="campus_id"
            value={educationType}
            style={{ height: 40 }}
            aria-label="Default select example"
            onChange={(e) => {
              setEducationType(e.target.value);
              getSectionsListOnTypeChange(e.target.value);
            }}
          >
            <option selected value={1}>
              Education
            </option>
            <option value={2}>Hifz</option>
          </select>
        </div>
        <div className="col-md-3">
          <label>
            <b>Select Section</b>
          </label>
          <select
            style={{ height: 40 }}
            className="form-select"
            name="section_id"
            // value={selectedSection}
            onChange={(e) => setselectedsection(e.target.value)}
            aria-label="Default select example"
          >
            <option value=""> Select Section</option>
            {allCampusSections.map((sec, index) => (
              <option key={index} value={sec.id}>
                {sec.name}
              </option>
            ))}
          </select>
        </div>

        <div style={{ height: 40 }} className="col-md-2 mt-12">
          <label className="checkbox checkbox-single">
            <input
              type="checkbox"
              onChange={(e) => setIncludeAnnual(e.target.checked)}
            />
            <span />
            &nbsp;&nbsp;&nbsp;Include FTM
          </label>
        </div>
        <div style={{ marginTop: 30, height: 40 }} className="col-md-4">
          {loading ? (
            <Spinner animation="border" />
          ) : (
            <button
              type={"submit"}
              onClick={getStudentWiseResult}
              className="btn btn-primary"
            >
              Get Result
            </button>
          )}
          {campusDetail.length > 0 ? (
            <>
              <ReactToPrint
                documentTitle="Student Wise result"
                // pageStyle={pageStyle}
                onAfterPrint={handleAfterPrint}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint={true}
                trigger={() => (
                  <button
                    disabled={printloading ? true : false}
                    className="btn btn-primary ml-3"
                  >
                    {printloading ? "Printing..." : "Print"}
                  </button>
                )}
                content={() => componentRef.current}
              />

              <button
                className="btn btn-primary ml-3"
                type="button"
                onClick={() => setviewModal(true)}
              >
                Download
              </button>
            </>
          ) : null}
        </div>
        {/* 
        <div style={{ marginTop: 30, height: 40 }} className="col-md-3">
          
        </div> */}
        {/* <div style={{ marginTop: 30, height: 40 }} className="col-md-3">
              
            </div> */}
      </div>

      {campusDetail.length > 0 &&
        educationType == 1 &&
        campusDetail.map((item) => (
          <ResultCard
            student_result_overall={
              Array.isArray(item.student_result_overall)
                ? item.student_result_overall.find(
                    (item) => item.exam == "FIRST TERM"
                  )
                : item.student_result_overall
            }
            studentDetails={item.student}
            campusDetail={campusDetail2}
            icon={icon}
            unitName={unitName}
            weekList={weekList}
            studentResultGradeWise={item.student_result_grade_wise}
            studentResultList={item.student_result}
            studentFinalResult={item.student_final_result}
            isInclude={Array.isArray(item.student_result_overall)}
            currentExam={
              Array.isArray(item.student_result_overall)
                ? item.student_result_overall.find(
                    (subitem) =>
                      subitem.exam == campusDetail2?.exam?.exam_type?.name
                  )
                : ""
            }
          />
        ))}

      {campusDetail.length > 0 &&
        educationType == 2 &&
        campusDetail.map((item) => (
          <ResultCardHifz
            student_result_overall={
              Array.isArray(item.student_result_overall)
                ? item.student_result_overall.find(
                    (item) => item.exam == "FIRST TERM"
                  )
                : item.student_result_overall
            }
            studentDetails={item.student}
            campusDetail={campusDetail2}
            icon={icon}
            unitName={unitName}
            weekList={weekList}
            studentResultGradeWise={item.student_result_grade_wise}
            studentResultHifz={item.student_result_hifz}
            studentResultHifzFinal={item.student_result_hifz_final}
            studentResultList={item.student_result}
            studentFinalResult={item.student_final_result}
            isInclude={Array.isArray(item.student_result_overall)}
            currentExam={
              Array.isArray(item.student_result_overall)
                ? item.student_result_overall.find(
                    (subitem) =>
                      subitem.exam == campusDetail2?.exam?.exam_type?.name
                  )
                : ""
            }
          />
        ))}

      <div
        ref={componentRef}
        id="printSection"
        style={{ visibility: "hidden" }}
      >
        {campusDetail.length > 0 &&
          educationType == 1 &&
          campusDetail.map((item) => (
            <div style={{ height: "100vh" }}>
              <ResulftCardForPrint
                student_result_overall={
                  Array.isArray(item.student_result_overall)
                    ? item.student_result_overall.find(
                        (item) => item.exam == "FIRST TERM"
                      )
                    : item.student_result_overall
                }
                studentDetails={item.student}
                campusDetail={campusDetail2}
                studentResultGradeWise={item.student_result_grade_wise}
                icon={icon}
                unitName={unitName}
                weekList={weekList}
                studentResultList={item.student_result}
                studentFinalResult={item.student_final_result}
                isInclude={Array.isArray(item.student_result_overall)}
                currentExam={
                  Array.isArray(item.student_result_overall)
                    ? item.student_result_overall.find(
                        (subitem) =>
                          subitem.exam == campusDetail2?.exam?.exam_type?.name
                      )
                    : ""
                }
              />
            </div>
          ))}

        {campusDetail.length > 0 &&
          educationType == 2 &&
          campusDetail.map((item) => (
            <div style={{ height: "100vh" }}>
              <ResulftCardHifzPrint
                student_result_overall={
                  Array.isArray(item.student_result_overall)
                    ? item.student_result_overall.find(
                        (item) => item.exam == "FIRST TERM"
                      )
                    : item.student_result_overall
                }
                studentDetails={item.student}
                campusDetail={campusDetail2}
                studentResultGradeWise={item.student_result_grade_wise}
                icon={icon}
                unitName={unitName}
                weekList={weekList}
                studentResultHifz={item.student_result_hifz}
                studentResultHifzFinal={item.student_result_hifz_final}
                studentResultList={item.student_result}
                studentFinalResult={item.student_final_result}
                isInclude={Array.isArray(item.student_result_overall)}
                currentExam={
                  Array.isArray(item.student_result_overall)
                    ? item.student_result_overall.find(
                        (subitem) =>
                          subitem.exam == campusDetail2?.exam?.exam_type?.name
                      )
                    : ""
                }
              />
            </div>
          ))}
      </div>

      {viewresult ? (
        <ViewResult
          modalVisible={viewModal}
          hideModal={() => setviewModal(false)}
          resultDetails={viewresult}
          getter={getStudentWiseResult}
        />
      ) : (
        ""
      )}
    </Paper>
  );
};

export default StudentWiseResult;
